<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import { useAppHeader } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppHeader";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useLoadFormatters } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useLoadFormatters";
import { useStore } from "@/core/services/store";
import { onUnmounted, ref, watch } from "vue";
import { resetValuesToSave } from "@/composables/useSalesChannelManagementApp";
import WorkflowStatus from "@/components/ExternalApps/SalesChannelManagementApp/components/WorkflowStatus.vue";
import InitialWorkflowDialog from "@/components/ExternalApps/SalesChannelManagementApp/InitialWorkflowDialog.vue";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

const store = useStore();
const { featureBaseForm, triggerConfirmation } = useAppHeader();
const { featureAreas, selectFeature } = useFeatureArea();
const { isAppInitialized } = useAppStatus();
const { loadFormatter } = useLoadFormatters();
const { selectedFeature, features } = useFeatures();
const { selectedIntegration, formValueChange } = useIntegration();
const { formHelper } = useAppHeader();

const workflowStatusRef = ref(null);
const forms = ref();
const showInitialWorkflowDialog = ref(false);
const emptyAreas = ref([]);

function onInitialWorkflowDialogAccept(formValues) {
  showInitialWorkflowDialog.value = false;

  workflowStatusRef.value?.[0].runWorkflow({
    limit: formValues.value.limit
  });
}

function onEmpty(item) {
  emptyAreas.value.push(item);
}

function onNotEmpty(item) {
  const index = emptyAreas.value.indexOf(item);
  emptyAreas.value.splice(index, 1);
}

// Set formHelper in order to validate the forms inside the save function
watch(
  () => forms.value,
  () => {
    formHelper.value = forms.value;
  }
);

// Reset values to prevent invalid data from being saved
onUnmounted(() => {
  resetValuesToSave();
});
</script>

<template>
  <div>
    <SkeletonLoading v-if="!isAppInitialized" />

    <div v-else>
      <div class="tabs d-flex justify-content-between mb-4">
        <PerfectScrollbar v-if="features.length > 0" class="d-flex align-end">
          <div
            v-for="(item, i) in features"
            :key="i"
            :class="{
              active: selectedFeature === item
            }"
            class="tab-title py-2 px-4"
            @click="selectFeature(item)"
          >
            <span class="">{{
              $t("salesChannelManagementApp.titles." + item)
            }}</span>
          </div>
        </PerfectScrollbar>
      </div>

      <div
        v-if="
          featureAreas.length <= 0 &&
          !selectedIntegration?.[selectedFeature]?.baseSettings?.value
        "
        class="alert alert-info"
      >
        <i class="fal fa-info-circle mr-2" />
        <span>{{ $t("salesChannelManagementApp.noConfigAvailable") }}</span>
      </div>

      <div v-else>
        <div class="feature-form">
          <!--   START: LIVE-/DEV-MODE   -->
          <FormHelper
            ref="forms"
            v-model="selectedIntegration[selectedFeature].baseSettings.value"
            :config="{
              title: $t('salesChannelManagementApp.liveDevMode'),
              snippetPrefix: 'salesChannelManagementApp',
              labelColClass: 'col-5',
              fieldColClass: 'col-7',
              fieldColClassXl: 'col-7',
              loadingGroup: 'salesChannelManagementApp',
              ...labelData
            }"
            :form="featureBaseForm"
            @change="
              triggerConfirmation(
                selectedIntegration[selectedFeature].baseSettings,
                $event,
                selectedFeature,
                'baseSettings'
              )
            "
          />
          <!--   END: LIVE-/DEV-MODE   -->
          <!--   START: INTEGRATION ALERT   -->
          <div
            v-if="isAppInitialized && selectedFeature"
            class="d-inline-block pt-4"
          >
            <div
              v-if="
                selectedIntegration?.[selectedFeature]?.baseSettings.value
                  .active
              "
              class="alert alert-success"
            >
              <i class="fal fa-info-circle" />
              <span class="ml-2">{{
                $t("salesChannelManagementApp.integration.live")
              }}</span>
            </div>
            <div v-else class="alert alert-warning">
              <div class="alert-icon">
                <i class="fal fa-warning" />
                <span class="ml-2">{{
                  $t("salesChannelManagementApp.integration.dev")
                }}</span>
              </div>
            </div>
          </div>
          <!--   END: INTEGRATION ALERT   -->
        </div>
        <template v-for="item in featureAreas">
          <div
            v-if="!emptyAreas?.includes(item) ?? true"
            :key="'feature-' + selectedFeature + '-' + item"
            class="feature-form"
          >
            <FormHelper
              v-if="
                Object.values(
                  store.getters.form('app.' + selectedFeature + '.' + item)
                ).length
              "
              ref="forms"
              v-model="selectedIntegration[selectedFeature][item].value"
              :config="{
                snippetPrefix: 'salesChannelManagementApp',
                title: $te('salesChannelManagementApp.title.' + item)
                  ? $t('salesChannelManagementApp.title.' + item)
                  : $t('salesChannelManagementApp.' + item),
                ...labelData,
                labelColClass: 'col-5',
                fieldColClass: 'col-7',
                fieldColClassXl: 'col-7',
                loadingGroup: 'salesChannelManagementApp',
                eagerValidation: true
              }"
              :form="store.getters.form('app.' + selectedFeature + '.' + item)"
              :custom-form-values="selectedIntegration[selectedFeature]"
              @load-formatter="loadFormatter"
              @change="formValueChange($event, selectedFeature, item, features)"
              @is-empty="onEmpty(item)"
              @not-empty="onNotEmpty(item)"
            />

            <div v-if="item === 'initialImportFile'">
              <WorkflowStatus
                ref="workflowStatusRef"
                :workflow-id="store.getters.initialProductWorkflowId('product')"
                @show-dialog="showInitialWorkflowDialog = true"
              />

              <InitialWorkflowDialog
                :show-initial-workflow-dialog="showInitialWorkflowDialog"
                @accept="onInitialWorkflowDialogAccept"
                @close="showInitialWorkflowDialog = false"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.actions {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -51px;
    top: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255 255 255) 100%
    );
  }
}

.feature-form {
  border-bottom: 1px solid #d0d5dd;
  padding: 1rem 0 2rem 0;
  margin: 1rem 0;

  &:last-child {
    border: none;
  }
}

.alert {
  margin-bottom: 0;
}

.tabs {
  border-bottom: 1px solid #d0d5dd;

  .tab-title {
    color: $color-connect-text;
    font-weight: bold;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &.active,
    &:hover {
      box-shadow: 0 -2px 0 $color-connect-primary inset;
      color: $color-connect-primary;
      text-decoration: none;
      font-weight: bold;
    }

    &.active {
      &:hover {
        color: $color-connect-primary;
      }
    }

    &:hover {
      color: $color-connect-text;
    }
  }
}
</style>
