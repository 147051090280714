<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useStore } from "@/core/services/store";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useLoadFormatters } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useLoadFormatters";
import { onMounted, onUnmounted, watch } from "vue";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import {
  addDeactivatedFeature,
  resetDeactivatedFeatures
} from "@/composables/useSalesChannelManagementApp";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

const { onFeaturesChanged } = useFeatures();
const { selectedIntegration, formValueChange } = useIntegration();
const { selectedArea } = useArea();
const { onFeaturesInput, selectSettingArea } = useFeatureArea();
const { loadFormatter } = useLoadFormatters();
const store = useStore();
const { hasOnlyMigration, hasMigration } = useAppStatus();
const {
  onChangeMigrationStatus,
  migrationChecks,
  migrationStatus,
  migrationStatusForm
} = useMigration();

onMounted(() => {
  selectSettingArea("wizard");

  // Quick Fix -> disable the change of the name field
  const nameField = store.getters
    .form("wizard.baseData")
    .find(field => field.name === "name");
  nameField.type = "hidden";
  nameField.disabled = true;

  if (!hasMigration.value || hasOnlyMigration.value) return;

  migrationStatus.value.active =
    !migrationChecks.value?.value[selectedIntegration.value.id]
      ?.migrationFinished;

  toggleActiveFieldDisableStatus();
});

watch(
  () => migrationStatus.value.active,
  () => {
    toggleActiveFieldDisableStatus();
  }
);

const unwatchFeatureChange = watch(
  () => selectedIntegration.value.features.value,
  () => {
    resetDeactivatedFeatures();

    for (const [key, value] of Object.entries(
      selectedIntegration.value.features.value
    )) {
      if (value !== false || !selectedIntegration.value?.[key]?.baseSettings)
        continue;

      addDeactivatedFeature(key);
    }
  }
);

function toggleActiveFieldDisableStatus() {
  let activeField = store.getters
    .form("wizard.baseData")
    .find(field => field.name === "active");

  if (!activeField || !hasMigration.value || hasOnlyMigration.value) return;
  activeField.disabled = migrationStatus.value.active;
}

onUnmounted(() => {
  unwatchFeatureChange();
});
</script>

<template>
  <div>
    <template v-if="selectedArea === 'wizard'">
      <FormHelper
        v-if="
          selectedIntegration.id in (migrationChecks?.value ?? []) &&
          !hasOnlyMigration &&
          hasMigration
        "
        v-model="migrationStatus"
        :form="migrationStatusForm"
        :config="{
          title: $t('salesChannelManagementApp.migration.changeMigrationTitle'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        @change="onChangeMigrationStatus($event)"
      />

      <FormHelper
        v-if="
          !store.getters?.areaBlacklist?.includes('wizard.baseData') ?? true
        "
        v-model="selectedIntegration.baseData.value"
        :form="store.getters.form('wizard.baseData')"
        :config="{
          title: $t('salesChannelManagementApp.livemode'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        @change="formValueChange($event, 'baseData')"
      />
      <FormHelper
        v-model="selectedIntegration.credentials.value"
        :form="store.getters.form('wizard.credentials')"
        :config="{
          title: $t('salesChannelManagementApp.credentials'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        @change="formValueChange($event, 'credentials')"
      />
      <FormHelper
        v-if="store.getters.form('wizard.tbOneChannelSelection')"
        v-model="selectedIntegration.tbOneChannelSelection.value"
        :config="{
          title: $t('salesChannelManagementApp.titles.tbOneChannelSelection'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        :form="store.getters.form('wizard.tbOneChannelSelection')"
        @change="formValueChange($event, 'tbOneChannelSelection')"
      />
      <FormHelper
        v-if="store.getters.form('wizard.sftpData')"
        v-model="selectedIntegration.sftpData.value"
        :config="{
          title: $t('salesChannelManagementApp.titles.sftpData'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        :form="store.getters.form('wizard.sftpData')"
        @change="formValueChange($event, 'sftpData')"
      />
      <FormHelper
        v-if="store.getters.form('wizard.features')"
        v-model="selectedIntegration.features.value"
        :form="store.getters.form('wizard.features')"
        :config="{
          title: $t('salesChannelManagementApp.features'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        @change="
          event => {
            onFeaturesChanged(event);
            formValueChange(event, 'features');
          }
        "
        @input="onFeaturesInput"
      />
    </template>

    <template v-else-if="selectedIntegration.settings[selectedArea]">
      <FormHelper
        :key="'settings-' + selectedArea"
        v-model="selectedIntegration.settings[selectedArea].value"
        :form="$store.getters.form('settings.' + selectedArea) || {}"
        :config="{
          snippetPrefix: 'salesChannelManagementApp',
          title: $t(
            'salesChannelManagementApp.titles.settings.' + selectedArea
          ),
          configValues: false,
          customVariables: ['scmaXentralFields'],
          labelStacked: selectedArea === 'productAssignment',
          ...labelData,
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp'
        }"
        @change="formValueChange($event, 'settings', selectedArea)"
        @load-formatter="loadFormatter"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.form-helper {
  border-bottom: 1px solid #d0d5dd;
  padding: 1rem 0 2rem 0;
  margin: 1rem 0;
}
</style>
