var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_setup.isAppInitialized)?_c(_setup.SkeletonLoading):_c('div',[_c('div',{staticClass:"tabs d-flex justify-content-between mb-4"},[(_setup.features.length > 0)?_c('PerfectScrollbar',{staticClass:"d-flex align-end"},_vm._l((_setup.features),function(item,i){return _c('div',{key:i,staticClass:"tab-title py-2 px-4",class:{
            active: _setup.selectedFeature === item
          },on:{"click":function($event){return _setup.selectFeature(item)}}},[_c('span',{},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.titles." + item)))])])}),0):_vm._e()],1),(
        _setup.featureAreas.length <= 0 &&
        !_setup.selectedIntegration?.[_setup.selectedFeature]?.baseSettings?.value
      )?_c('div',{staticClass:"alert alert-info"},[_c('i',{staticClass:"fal fa-info-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.noConfigAvailable")))])]):_c('div',[_c('div',{staticClass:"feature-form"},[_c(_setup.FormHelper,{ref:"forms",attrs:{"config":{
            title: _vm.$t('salesChannelManagementApp.liveDevMode'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ..._setup.labelData
          },"form":_setup.featureBaseForm},on:{"change":function($event){return _setup.triggerConfirmation(
              _setup.selectedIntegration[_setup.selectedFeature].baseSettings,
              $event,
              _setup.selectedFeature,
              'baseSettings'
            )}},model:{value:(_setup.selectedIntegration[_setup.selectedFeature].baseSettings.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration[_setup.selectedFeature].baseSettings, "value", $$v)},expression:"selectedIntegration[selectedFeature].baseSettings.value"}}),(_setup.isAppInitialized && _setup.selectedFeature)?_c('div',{staticClass:"d-inline-block pt-4"},[(
              _setup.selectedIntegration?.[_setup.selectedFeature]?.baseSettings.value
                .active
            )?_c('div',{staticClass:"alert alert-success"},[_c('i',{staticClass:"fal fa-info-circle"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.integration.live")))])]):_c('div',{staticClass:"alert alert-warning"},[_c('div',{staticClass:"alert-icon"},[_c('i',{staticClass:"fal fa-warning"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.integration.dev")))])])])]):_vm._e()],1),_vm._l((_setup.featureAreas),function(item){return [(!_setup.emptyAreas?.includes(item) ?? true)?_c('div',{key:'feature-' + _setup.selectedFeature + '-' + item,staticClass:"feature-form"},[(
              Object.values(
                _setup.store.getters.form('app.' + _setup.selectedFeature + '.' + item)
              ).length
            )?_c(_setup.FormHelper,{ref:"forms",refInFor:true,attrs:{"config":{
              snippetPrefix: 'salesChannelManagementApp',
              title: _vm.$te('salesChannelManagementApp.title.' + item)
                ? _vm.$t('salesChannelManagementApp.title.' + item)
                : _vm.$t('salesChannelManagementApp.' + item),
              ..._setup.labelData,
              labelColClass: 'col-5',
              fieldColClass: 'col-7',
              fieldColClassXl: 'col-7',
              loadingGroup: 'salesChannelManagementApp',
              eagerValidation: true
            },"form":_setup.store.getters.form('app.' + _setup.selectedFeature + '.' + item),"custom-form-values":_setup.selectedIntegration[_setup.selectedFeature]},on:{"load-formatter":_setup.loadFormatter,"change":function($event){return _setup.formValueChange($event, _setup.selectedFeature, item, _setup.features)},"is-empty":function($event){return _setup.onEmpty(item)},"not-empty":function($event){return _setup.onNotEmpty(item)}},model:{value:(_setup.selectedIntegration[_setup.selectedFeature][item].value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration[_setup.selectedFeature][item], "value", $$v)},expression:"selectedIntegration[selectedFeature][item].value"}}):_vm._e(),(item === 'initialImportFile')?_c('div',[_c(_setup.WorkflowStatus,{ref:"workflowStatusRef",refInFor:true,attrs:{"workflow-id":_setup.store.getters.initialProductWorkflowId('product')},on:{"show-dialog":function($event){_setup.showInitialWorkflowDialog = true}}}),_c(_setup.InitialWorkflowDialog,{attrs:{"show-initial-workflow-dialog":_setup.showInitialWorkflowDialog},on:{"accept":_setup.onInitialWorkflowDialogAccept,"close":function($event){_setup.showInitialWorkflowDialog = false}}})],1):_vm._e()],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }